<template>
  <div class="container">
    <h2>Бухгалтерия</h2>
    <div class="bookkeeping-selects-block">
      <Select
        :default="selectedYear"
        :options="availableDates.years"
        :mode="['default']"
        customWidth="180px"
        @selectOption="setYear"
      />
      <Select
        :default="displayedMonth"
        :options="availableDates.months"
        showModel="title"
        :mode="['default']"
        customWidth="180px"
        @selectOption="setMonth"
      />
      <Select
        :default="projectName || 'не выбрано'"
        :options="projectsByMonth || []"
        showModel="name"
        :mode="['default']"
        customWidth="180px"
        :disabled="reportType !== 'one'"
        @selectOption="setProject"
        :onlyOneLine="true"
      />
    </div>
    <ul class="bookkeeping-radio-list">
      <li>
        <label class="bookkeeping-radio-list__item-label">
          <input
            type="radio"
            value="consolidated"
            v-model="reportType"
            :disabled="selectedYear === null || selectedMonth === null"
          />Сводный отчет по всем проектам за месяц
        </label>
      </li>
      <li>
        <label class="bookkeeping-radio-list__item-label">
          <input
            type="radio"
            value="archive"
            v-model="reportType"
            :disabled="selectedYear === null || selectedMonth === null"
          />Архив с отчетами по всем проектам за месяц
        </label>
      </li>
      <li>
        <label class="bookkeeping-radio-list__item-label">
          <input type="radio" value="one" v-model="reportType" />Месячный отчет
          по проекту
        </label>
      </li>
    </ul>
    <Button
      theme="common"
      :disabled="
        (reportType === 'one' &&
          (!isProjectIncludesInMonth || projectID === null)) ||
        selectedYear === null ||
        selectedMonth === null
      "
      @click="loadReport"
      >Скачать
    </Button>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { MONTHS } from "@/assets/constants.js";
import { saveAs } from "file-saver";
import Select from "@/components/Select.vue";
import Button from "@/components/Button.vue";

export default {
  name: "BookkeepingPage",
  components: {
    Select,
    Button,
  },
  data() {
    return {
      selectedYear: null,
      selectedMonth: null,
      projectID: null,
      projectName: null,
      reportType: "consolidated",
    };
  },
  async mounted() {
    await this.loadPlanningsList({ limit: 999 });
    const plannings = this.planningsListAs("object");
    const currentYear = this.getMyDate.year();
    const currentMonth = this.getMyDate.month() + 1;
    const prevDate = plannings.find(
      (date) =>
        (date.year === currentYear && date.month < currentMonth) ||
        date.year < currentYear
    );
    if (prevDate) {
      this.selectedYear = prevDate.year;
      this.selectedMonth = prevDate.month;
    }
  },
  watch: {
    selectedYear(year) {
      const plannings = this.planningsListAs("object");
      const date = plannings.find(
        (date) => date.year === year && date.month === this.selectedMonth
      );
      if (!date) {
        this.selectedMonth = null;
      }
    },
    isProjectIncludesInMonth(isTrue) {
      if (!isTrue) {
        this.projectName = null;
        this.projectID = null;
      }
    },
    reportType(value) {
      if (value !== "one") {
        this.projectName = null;
        this.projectID = null;
      }
    },
    async monthID(id) {
      if (id >= 0) {
        this.setProjectsTypes([]);
        this.setMonthId(id);
        await this.loadProjectsListByMonth(id);
      }
    },
  },
  computed: {
    ...mapState({
      projectsByMonth: (state) => state.projectsStore.projectsByMonth,
    }),
    ...mapGetters({
      getMyDate: "userStore/getMyDate",
      planningsListAs: "planningsStore/planningsListAs",
      getMonthID: "planningsStore/getMonthID",
    }),
    availableDates() {
      const dates = this.planningsListAs("object");
      return {
        years: [...new Set(dates.map((date) => date.year))],
        months: dates
          .filter((date) => date.year === this.selectedYear)
          .map((date) => ({
            id: date.month,
            title: MONTHS[date.month - 1],
          })),
      };
    },
    displayedMonth() {
      return MONTHS[this.selectedMonth - 1];
    },
    monthID() {
      return this.getMonthID({
        year: this.selectedYear,
        month: this.selectedMonth,
      });
    },
    isProjectIncludesInMonth() {
      if (this.projectID === null) {
        return true;
      }
      return Boolean(
        this.projectsByMonth.find((project) => project.id === this.projectID)
      );
    },
  },
  methods: {
    ...mapActions({
      loadPlanningsList: "planningsStore/loadPlanningsList",
      loadProjectsListByMonth: "projectsStore/loadProjectsListByMonth",
      uploadConsolidatedReport: "reportsStore/uploadConsolidatedReport",
      uploadAllReports: "reportsStore/uploadAllReports",
      uploadProjectReport: "reportsStore/uploadProjectReport",
    }),
    ...mapMutations({
      setProjectsTypes: "planningsStore/setProjectsTypes",
      setMonthId: "planningsStore/setMonthId",
    }),
    setYear(year) {
      this.selectedYear = year;
    },
    setMonth(month) {
      this.selectedMonth = month.id;
    },
    setProject({ id, name }) {
      this.projectID = id;
      this.projectName = name;
    },
    async loadReport() {
      let report = null;
      let fileName = "";
      let fileFormat = "xlsx";
      if (this.reportType === "consolidated") {
        report = await this.uploadConsolidatedReport(this.monthID);
        fileName = "Сводный отчет по проектам";
      } else if (this.reportType === "archive") {
        report = await this.uploadAllReports(this.monthID);
        fileName = "Отчеты по проектам";
        fileFormat = "zip";
      } else if (this.reportType === "one") {
        report = await this.uploadProjectReport({
          monthID: this.monthID,
          projectID: this.projectID,
        });
        fileName = `Отчет по проекту ${this.projectName}`;
      }
      saveAs(
        report,
        `${fileName} - ${MONTHS[this.selectedMonth - 1]} ${
          this.selectedYear
        }.${fileFormat}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bookkeeping-block {
  display: flex;
  gap: 20px;
}

.bookkeeping-selects-block {
  display: flex;
  gap: 20px;
}

.bookkeeping-radio-list {
  padding-left: 0;
  list-style: none;
  margin: 20px 0;
}

.bookkeeping-radio-list__item-label {
  cursor: pointer;

  & input {
    margin-right: 8px;
  }
}

.bookkeeping-radio-list__item-label--disabled {
  cursor: default;
  color: #cccccc;
}

.bookkeeping-radio-list__item-label--help {
  color: #f08876;
  font-weight: 600;
}
</style>
